<template>
	<div>
        <timer-util />
		<top-menu />

		 <!-- Start Content-->
        <div class="container-fluid mm-active">
        	<!-- Begin page -->
            <div class="wrapper mm-show">

            	<!-- ========== Left Sidebar Start ========== -->
                <side-menu />

                <div class="content-page">
                    <div class="content">
                    	<transition name="fade" mode="out-in"><router-view></router-view></transition>
                    </div>
                </div>

            </div>
        </div>
        
        <tracker-util />
	</div>
</template>

<script>
import TopMenu from '@/components/menu/Top.vue'
import SideMenu from '@/components/menu/Side.vue'

import TimerUtil from '@/components/util/Timer.vue'
import TrackerUtil from '@/components/util/Tracker.vue'

export default {
	name: 'MainLayout',
    mounted() {
        $('body').tooltip({
            selector: '[rel=tooltip]',
            animation: false,
            trigger: 'hover'
        });
    },
    components: {
        TopMenu,
        SideMenu,
        TimerUtil,
        TrackerUtil    
    }
};
</script>

<style>
@media (min-width: 992px) {
	body[data-layout='detached'] .container-fluid,
	body[data-layout='detached'] .container-sm,
	body[data-layout='detached'] .container-md,
	body[data-layout='detached'] .container-lg,
	body[data-layout='detached'] .container-xl {
		max-width: 1400px;
	}
}

@media (max-width: 768px) {
	.topnav-navbar .topnav-logo {
		margin-left: 10px;
	}
}

.topnav-navbar .topnav-logo {
    margin-left: 60px;
}
body[data-leftbar-compact-mode="condensed"] .topnav-navbar .topnav-logo {
    margin-left: 12px;
}
@media (max-width: 767.98px) {
    .topnav-navbar .topnav-logo {
        margin-left: 10px;
    }
}

.nav-user .account-user-name {
    line-height: 39px;
}

body[data-layout="detached"] .leftbar-user .leftbar-user-name {
    margin-left: 0px;
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.1s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.button-menu-mobile span {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.vue-dropzone {
    background-color: #adb5bd !important;
    color: #fff !important;
}

a {
    cursor:pointer;
}
</style>
