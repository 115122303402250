<template>
    <transition-group class="side-nav-second-level mm-show" name="flip-list" tag="ul">
        <li v-for="(item) in items" :key="item.id">
            <router-link :to="{ name: 'ProjectView', params: { id: item.id } }" @click.native="menu_toggle(false)">
               <i class="mdi mdi-star mr-1"></i> <b>{{item.title}}</b>
            </router-link>
        </li>
    </transition-group>
</template>

<script>
import { mapMutations } from 'vuex'
import log from '@/plugins/log'

export default {
    name: 'ProjectListMenu',
    data: () => {
        return {
            items: null
        }
    },
    beforeMount() {
        this.data()
    },
    methods: {
        ...mapMutations({
            menu_toggle: 'navigation/mobile_menu_toggle'
        }),
        async data() {
            await this.$orm.queryAndSubscribe({
				project: {
                    where: { favorited_by: { user_id: { _eq: this.$store.state.auth.user.id } } },
					order_by: { updated_at: 'desc' },
                    fields: [
                        'id', 
                        'title',
                    ]
				}
			}, ([err, response]) => {
				if(err) {
					log.error('ProjectListMenu', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Error", "Can't send request", "top-right", "", "error")
				}
				log.debug('ProjectListMenu', 'methods.data', '$orm.queryAndSubscribe', response)
				this.items = response
			})
        }
    }
}
</script>

<style>
.side-nav-second-level li a {
    padding: 7px 20px 7px 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
}
.side-nav-second-level li a:hover {
    background-color: #fafbfe;
}
.flip-list-move {
  transition: transform 1s;
}
.left-side-menu .side-nav .mm-active .router-link-active {
    color: #3d73dd !important;
}
</style>