<template>
    <div class="navbar-custom topnav-navbar">
        <div class="container-fluid">
            <!-- LOGO -->
            <router-link class="topnav-logo" :to="{ name: 'ProjectList'}">	
                <span class="topnav-logo-lg">
                    <img src="@/assets/images/logo/cover_photo_1.png" alt="" height="36">
                </span>
                <span class="topnav-logo-sm">
                    <img src="@/assets/images/logo/cover_photo_sm.png" alt="" height="36">
                </span>
            </router-link>

            <ul class="list-unstyled topbar-right-menu float-right mb-0">
                <notifications-dropdown />
            </ul>

            <a class="button-menu-mobile disable-btn" @click.prevent="$store.commit('navigation/mobile_menu_toggle')">
                <div class="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </a>

        </div>
    </div>
</template>

<script>
import NotificationsDropdown from '@/components/notification/MenuDropdown.vue';

export default {
    name: 'TopMenu',
    components: {
        NotificationsDropdown
    }
}
</script>