<template>
    <div ref="image_modal" :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body p-0">
					<img :src="$cdn({
                        image,
                        all: {
                            w: 1000
                        }
                    })" class="">
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-dismiss="modal">Закрыть</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
export default {
    name: 'CategoryModal',
    data: () => {
        return {
            hashtag: 'image-modal',
            image: null
        }
    },
    beforeMount() {

    },
    methods: {
        openModal(image) {
            this.image = image
            $(this.$refs.image_modal).modal('show')
        },
        closeModal() {
            $(this.$refs.image_modal).modal('hide')
        },
    }
}
</script>

<style scoped>
.modal-body img {
    width: 100%;
    height: auto;
}
</style>