<template>
	<div ref="invite_to_project" :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body pt-4">
					<div class="form-group">
						<input class="form-control" type="text" placeholder="E-mail пользователя" v-model="form.email" @keyup.enter="submitForm()" />
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-dismiss="modal">Закрыть</button>

					<button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()">
						Отправить приглашение
					</button>
					<button v-else class="btn btn-primary" type="button" disabled>
						<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
						Загрузка...
					</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	<!-- /.modal -->
</template>

<script>
import log from '@/plugins/log'
import { to } from '@/plugins/utils'

export default {
	name: 'InviteProjectModal',
	data: () => {
		return {
            hashtag: 'invite_to_project',
			_form: {
                project_id: '',
				email: '',
			},
			form: {},
			loading: false,
		};
	},
	mounted() {
		this.resetForm();
	},
	methods: {
        openModal(project_id) {
            this.form.project_id = project_id
            $(this.$refs.invite_to_project).modal('show')
        },
        closeModal() {
			$(this.$refs.invite_to_project).modal('hide')
		},
		resetForm() {
			this.form = Object.assign({}, this._form);
		},
		async submitForm() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.email)
				return $.NotificationApp.send('Ошибка', 'Введите email', 'top-right', '', 'error');

            this.loading = true;

            var [err, response] = await to( this.$http.post('/project/invite', this.form) )
            if(err) {
                this.loading = false

                if(err.response && err.response.data && err.response.data.error) {
                    let error = err.response.data.error

                    if(error === 'no_user_found')
                        return $.NotificationApp.send("Ошибка", 'Пользователь не найден', "top-right", "", "error")
                    if(error === 'cant_invite_yourself')
                        return $.NotificationApp.send("Ошибка", 'Вы не можете пригласить самого себя в проект', "top-right", "", "error")
                    if(error === 'failed_validation')
                        return $.NotificationApp.send("Ошибка", 'Какое-то из полей заполнено не верно', "top-right", "", "error")
                }
                log.error('methods.submitForm', '$http.request', err)
                return $.NotificationApp.send("Ошибка", err.message, "top-right", "", "error")
            }
            if(!response.data.success) {
                this.loading = false
                log.error('methods.submitForm', '$http.response', response.data)
                return $.NotificationApp.send("Ошибка", response.data.error, "top-right", "", "error")
            }
            
			this.loading = false;

			this.resetForm();
            this.closeModal();
		},
	},
};
</script>

<style scoped></style>
