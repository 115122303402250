<template>
	<transition name="fade">
		<div class="help-box text-center left-menu-timer" v-if="current_timer && timePassedFormat">
	        <h5 class="mt-3">Таймер</h5>
	        <p class="mb-1 timer">{{ timePassedFormat(currentTimerPassed) }}</p>
	        <button v-if="!loading" class="btn btn-outline-danger btn-rounded btn-small mr-1 btn-do" @click="stopTimer()">
	        	<i class="mdi mdi-timer-off"></i> <span>завершить</span>
	        </button>
			<button v-else class="btn btn-outline-danger btn-rounded btn-small mr-1" type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            </button>

	        <router-link :to="linkTo" class="btn btn-outline-primary btn-rounded btn-small btn-do">
	        	<i class="uil-briefcase"></i> 
				<span v-if="current_timer.subtask_id != null">подзадача</span>
				<span v-else-if="current_timer.task_id != null">задача</span>
				<span v-else>проект</span>
	        </router-link>
	    </div>
	</transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'LeftMenuTimer',
	data: () => {
		return {
			loading: false
		}
	},
	computed: {
		...mapState({
			current_timer: state => state.timer.current_timer,
			currentTimerPassed: state => state.timer.currentTimerPassed
		}),
		...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
		linkTo() {
			if(this.current_timer.task_id != null) return {
				name: 'TaskView',
				params: {
					id: this.current_timer.project_id,
					task_id: this.current_timer.task_id
				}
			}
			if(this.current_timer.project_id != null) return {
				name: 'ProjectView',
				params: {
					id: this.current_timer.project_id
				}
			}
		}
	},
	methods: {
		async stopTimer() {
			this.loading = true
			await this.$store.dispatch('timer/finishTimer', {$orm: this.$orm, object: this.object})
			setTimeout(() => {
				this.loading = false
			}, 300)
		}
	}
}
</script>

<style>
.left-menu-timer .timer {
	font-size: 40px !important;
}
.btn-do {
	width: 46px;
	overflow: hidden;
	white-space: pre;
}
.btn-do span {
	display: none;
	opacity: 0;
}
.btn-do:hover span {
	display: inline;
	opacity: 1;
	transition: opacity 0.15s;
}
.btn-do:hover {
	width: 110px;
	transition: width 0.15s;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
	opacity: 0;
}
</style>