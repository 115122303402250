var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"image_modal",staticClass:"modal fade",attrs:{"id":_vm.hashtag,"tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body p-0"},[_c('img',{attrs:{"src":_vm.$cdn({
                        image: _vm.image,
                        all: {
                            w: 1000
                        }
                    })}})]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-light",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Закрыть")])])
}]

export { render, staticRenderFns }