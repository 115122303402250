<template>
	<li class="dropdown notification-list" :class="{ show }">
		<a class="nav-link arrow-none" href="#" @click.prevent="show = !show" id="topbar-notifydrop" role="button" aria-haspopup="true" aria-expanded="false">
			<i class="dripicons-bell noti-icon"></i>
			<span class="noti-icon-badge" v-if="unread_notifications.length"></span>
		</a>
		<div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-xl" aria-labelledby="topbar-notifydrop">
			<!-- item-->
			<div class="dropdown-item noti-title">
				<h5 class="m-0">
					<span class="float-right">
						<!-- <a href="javascript: void(0);" class="text-dark">
							<small>Clear All</small>
						</a> --> </span
					>Уведомления
				</h5>
			</div>

			<div class="notification-wrapper" v-if="notifications && notifications.length">
				<notification-row v-for="notification in notifications" :key="notification.id" :notification="notification" @close_dropdown="show = false" />
			</div>
			<div v-else class="text-center pb-3 pt-2">Уведомлений пока нет</div>

			<a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all" @click.prevent="show = false">
				закрыть
			</a>
		</div>
		<div v-if="show" class="outside" v-on:click="show = false"></div>
	</li>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Howl } from 'howler';

import NotificationRow from '@/components/notification/menu-dropdown/DropdownRow.vue';

export default {
	name: 'TopMenuNotificationsDropdown',
	data: () => {
		return {
			notifications: [],
			show: false,
			howl: null,
		};
	},
	computed: {
		...mapState({
			user: state => state.auth.user,
		}),
		...mapGetters({
			authenticated: 'auth/authenticated',
		}),
		unread_notifications() {
			return this.notifications && this.notifications.filter(item => item.processed_at == null);
		},
	},
	beforeMount() {
		this.data()
	},
	mounted() {
		this.howl = new Howl({
			src: ['/sound/notification.mp3', '/sound/notification.ogg'],
			autoplay: false,
			loop: false,
			volume: 0.75,
		});
	},
	methods: {
        async data() {
            await this.$orm.queryAndSubscribe({
				notification: {
					limit: 10,
					order_by: { created_at: 'desc' }
				}
			}, ([err, response]) => {

				if (this.notifications.length) {
					response.forEach(notification => {
						if (this.notifications.findIndex(notif => notif.id == notification.id) == -1) this.new_notification(notification);
					});
				}

				this.notifications = response
			})
        },	
		new_notification(notification) {
			this.howl && this.howl.play();
			return $.NotificationApp.send('Notification', notification.text, 'top-right', '', 'info');
		},
	},
	components: {
		NotificationRow,
	},
};
</script>

<style>
.simplebar-content-wrapper {
	overflow: auto;
}
[data-simplebar] {
	overflow: auto;
}
.notification-list .dropdown-menu.dropdown-menu-right {
	padding-bottom: 0px;
}
.dropdown-xl {
	width: 400px;
}
.notification-wrapper {
	overflow: auto;
	max-height: 350px;
}
.outside {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
}
</style>
