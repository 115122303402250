<template>
    <transition-group class="side-nav-second-level mm-show" name="flip-list" tag="ul">
        <li v-for="(item) in items" :key="item.id">
            <router-link :to="{ name: 'ProjectCategory', params: { category: item.id } }" @click.native="menu_toggle(false)">
               <i class="mdi mdi-flag-variant mr-1"></i> <b>{{item.title}}</b>
            </router-link>
        </li>
    </transition-group>
</template>

<script>
import { mapMutations } from 'vuex'
import log from '@/plugins/log'

export default {
    name: 'CategoryMenuList',
    data: () => {
        return {
            items: null
        }
    },
    beforeMount() {
        this.data()
    },
    methods: {
        ...mapMutations({
            menu_toggle: 'navigation/mobile_menu_toggle'
        }),
        async data() {
            await this.$orm.queryAndSubscribe({
				project_category: {
                    where: { project2category: { id: { _is_null: false } } },
                    fields: `
                        id
                        title
                    `
				}
			}, ([err, response]) => {
				if(err) {
					log.error('CategoryMenuList', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Error", "Can't send request", "top-right", "", "error")
				}
				log.debug('CategoryMenuList', 'methods.data', '$orm.queryAndSubscribe', response)
				this.items = response
			})
        }
    }
}
</script>

<style>
.side-nav-second-level li a {
    padding: 7px 20px 7px 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
}
.side-nav-second-level li a:hover {
    background-color: #fafbfe;
}
.flip-list-move {
  transition: transform 1s;
}
.left-side-menu .side-nav .mm-active .router-link-active {
    color: #3d73dd !important;
}
</style>