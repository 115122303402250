<template>
  
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'TimerUtil',
    data: () => {
        return {
        }
    },
    async beforeMount() {
        await this.data()
    },
    computed: {
		...mapState({
			current_timer: state => state.timer.current_timer,
			currentTimerPassed: state => state.timer.currentTimerPassed
        }),
        ...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
    },
    methods: {
        async data() {
            //live timer
            await this.$orm.queryAndSubscribe({
				timer: {
					where: {
                        _and: {
                            user_id: { _eq: this.$store.state.auth.user.id },
                            time_start: {_is_null: false}, 
                            time_finish: {_is_null: true}
                        }
                    }
				}
			}, (response) => {
                this.$store.commit('timer/parse_timer_response', response)
			}, { getFirst: true })
        },
        changeFavicon(icon_src) {
            const favicon = document.getElementById('favicon');
            favicon.href = icon_src
        }
    },
    watch: {
        currentTimerPassed: function(value) {
            if(value > 0) {
                document.title = `${this.timePassedFormat(value)} | TO DO FOR ME - manage your time`

                let tick = 0
                if(value % 3 == 2) tick = 1 
                this.changeFavicon(`/tick-${ tick }.ico`)
            } else { 
                document.title = 'TO DO FOR ME - manage your time'
                this.changeFavicon('/favicon.ico')
            }
        }
    }
}
</script>