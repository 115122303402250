<template>
	<div class="notify-wrapper">
		<div class="notify-icon bg-secondary">
			<i class="mdi mdi-message-outline"></i>
		</div>
		<div>
			<p class="notify-details" v-html="notification.text"></p>
			<date-text :date="notification.created_at" />
		</div>
	</div>
</template>

<script>
import DateText from '@/components/notification/util/Date.vue';

export default {
	name: 'NotificationTeamInvite',
	props: ['notification'],
	components: {
		DateText,
	},
};
</script>
