<template>
	<div class="notify-wrapper" @click="$router.push({ name: 'TaskView', params: { id: notification.meta.project, task_id: notification.meta.task } })">
		<div class="notify-icon bg-dark">
			<i class="mdi mdi-briefcase-download"></i>
		</div>
		<div>
			<p class="notify-details" v-html="notification.text"></p>
			<date-text :date="notification.created_at" />
		</div>
	</div>
</template>

<script>
import DateText from '@/components/notification/util/Date.vue';

export default {
	name: 'NotificationTeamInvite',
	props: ['notification'],
	components: {
		DateText,
	},
};
</script>