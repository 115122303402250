<template>
	<small class="text-muted">{{ format_date(date) }}</small>
</template>

<script>
import dayjs from '@/plugins/day'

export default {
	name: 'NotificationDateUtil',
	props: ['date'],
	methods: {
		format_date(date) {
			return dayjs(date).format('LLL');
		},
	},
};
</script>
