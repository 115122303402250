<template>
    <div>
        <div class="left-side-menu left-side-menu-detached mm-active" :class="{ 'mobile-show': $store.state.navigation.mobile_menu }">
            <div class="leftbar-user">
                <router-link :to="{ name: 'ProfileView', params: { id: user.id } }" class="side-nav-link" @click.native="menu_toggle(false)">
                    <profile-avatar :user="user" :size="90" />
                    <span class="leftbar-user-name">{{ user.name }}</span>
                </router-link>
            </div>

                <!--- Sidemenu -->
            <ul class="metismenu side-nav">
                <li class="side-nav-title side-nav-item">Разделы</li>

                <li class="side-nav-item" :class="{'mm-active': activeRouteName('Project')}">
                    <router-link :to="{ name: 'ProjectList' }" class="side-nav-link" @click.native="menu_toggle(false)">
                        <i class="uil-briefcase"></i>
                        <span>Проекты</span>
                    </router-link>

                    <project-list />
                </li>

                <li class="side-nav-item mt-1">
                    <a class="side-nav-link" @click="$refs.category_menu.openModal();menu_toggle(false)">
                        <i class="uil-briefcase"></i>
                        <span>Категории</span>
                    </a>

                    <category-list />
                </li>

                <li class="side-nav-item" :class="{'mm-active': activeRouteName('TimerList')}">
                    <router-link :to="{ name: 'TimerList' }" class="side-nav-link" @click.native="menu_toggle(false)">
                        <i class="uil-watch"></i>
                        <span>Таймеры</span>
                    </router-link>
                </li>
            </ul>

            <ul class="metismenu side-nav">
                <li class="side-nav-title side-nav-item">Профиль</li>
                <li class="side-nav-item">
                    <router-link :to="{ name: 'ProfileView', params: { id: user.id } }" class="side-nav-link" @click.native="menu_toggle(false)">
                        <i class="mdi mdi-account"></i>
                        <span>Профиль</span>
                    </router-link>
                </li>
                <li class="side-nav-item">
                    <router-link :to="{ name: 'SettingsProfile' }" class="side-nav-link" @click.native="menu_toggle(false)">
                        <i class="mdi mdi-account-cog-outline"></i>
                        <span>Настройки</span>
                    </router-link>
                </li>
                <li class="side-nav-item">
                    <a href="javascript:void(0);" class="side-nav-link" @click.prevent="logout()" @click="menu_toggle(false)">
                        <i class="mdi mdi-logout"></i>
                        <span>Выйти</span>
                    </a>
                </li>
            </ul>

            <ul class="metismenu side-nav" v-if="user.email == 'assorium@gmail.com'">
                <li class="side-nav-item">
                    <router-link :to="{ name: 'TestPage' }" class="side-nav-link" @click.native="menu_toggle(false)">
                        <i class="mdi mdi-ufo"></i>
                        <span>Test</span>
                    </router-link>
                </li>
            </ul>

            <left-menu-timer />

            <div class="clearfix"></div>
            
        </div>
        <div v-if="$store.state.navigation.mobile_menu" class="outside" @click="menu_toggle(false)"></div>

        <category-modal ref="category_menu" />
        <image-modal ref="image_modal" />
        <invite-project-modal ref="invite_project_modal" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ProfileAvatar from '@/components/profile/Avatar.vue'
import LeftMenuTimer from '@/components/timer/LeftMenuTimer.vue'

import ProjectList from '@/components/project/MenuList.vue'
import CategoryList from '@/components/menu/_parts/CategoryList.vue'

import CategoryModal from '@/components/modal/Category.vue'
import ImageModal from '@/components/modal/Image.vue'
import InviteProjectModal from '@/components/modal/InviteProject.vue'

export default {
    name: 'SideMenu',
    computed: {
		...mapState({
			user: state => state.auth.user,
		}),
    },
    mounted() {
        this.$store.commit('modal/set_image', this.$refs.image_modal)
        this.$store.commit('modal/set_invite2project', this.$refs.invite_project_modal)
    },
    methods: {
        ...mapMutations({
            menu_toggle: 'navigation/mobile_menu_toggle'
        }),
		async logout() {
            await this.$store.dispatch('auth/logout')
		},
        activeRouteName(match) {
            return this.$router.currentRoute.name.indexOf(match) !== -1
        }
    },
    components: {
        ProfileAvatar,
        LeftMenuTimer,
        ProjectList,
        CategoryList,
        CategoryModal,
        ImageModal,
        InviteProjectModal
    }
}
</script>

<style scoped>
.left-side-menu {
    height: 100%;
}

@media (max-width: 767.98px) {
    .left-side-menu.mobile-show {
        position: fixed !important;
        left: 0 !important;
        overflow-y: auto !important;
        margin-top: 0 !important;
        display: block;
        z-index: 2;
    }
    .outside {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100;
    }
}
</style>