<template>
	<a href="javascript:void(0);" class="dropdown-item notify-item" :class="{ 'not-viewed': !is_viewed }" @mouseover.once="setViewed()">
		<simple-text v-if="notification.type == 'simple_text' || notification.type == 'message:text'" :notification="notification" />
		<project-invite v-if="notification.type == 'project:invite'" :notification="notification" />
		<long-timer v-if="notification.type == 'timer:long'" :notification="notification" />
		<subtask-status v-if="notification.type == 'subtask:new' || notification.type == 'subtask:finished'" :notification="notification" />
		<task-status v-if="notification.type == 'task:new' || notification.type == 'task:finished'" :notification="notification" />
	</a>
</template>

<script>
import log from '@/plugins/log';
import { to } from '@/plugins/utils';

import SimpleText from '@/components/notification/menu-dropdown/rows/SimpleText.vue';
import ProjectInvite from '@/components/notification/menu-dropdown/rows/ProjectInvite.vue';
import LongTimer from '@/components/notification/menu-dropdown/rows/LongTimer.vue';
import SubtaskStatus from '@/components/notification/menu-dropdown/rows/SubtaskStatus.vue';
import TaskStatus from '@/components/notification/menu-dropdown/rows/TaskStatus.vue';

export default {
	name: 'NotificationRow',
	props: ['notification'],

	computed: {
		is_viewed() {
			return this.notification.processed_at != null;
		},
	},
	methods: {
		async setViewed() {
			if (this.is_viewed) return;

			var [err] = await this.$orm.mutate({
				notification: {
					update: {
						where: { id: { _eq: this.notification.id }},
						_set: { processed_at: 'now()' }
					}
				}
			})
			if(!err)
				this.notification.processed_at = new Date()
		},
	},
	components: {
		SimpleText,
		ProjectInvite,
		LongTimer,
		SubtaskStatus,
		TaskStatus
	},
};
</script>

<style>
.not-viewed {
	color: #272e37;
	text-decoration: none;
	background-color: #ececec;
}
.notification-list .notify-item .notify-details {
	white-space: normal;
	margin-left: 0px;
}
.notify-details b {
	font-weight: 700 !important;
}
.notify-wrapper {
	display: flex;
}
</style>
